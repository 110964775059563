import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import Authenticated from "./components/login-page/Authenticated";
import FrameTestPage from "./components/login-page/FrameTestPage";
import LogIn from "./components/login-page/Login";
import TokenLogin from "./components/login-page/TokenLogin";
import MapBoxGl from "./components/mapBox/MapBoxGl";
import { GoogleAnalyticsArray as GA } from "./components/commons/GoogleAnalytics";
import Home from "./components/shell/Home";
import Shell from "./components/shell/Shell";
import Logout from "./components/login-page/Logout";
import UserPreferences from "./components/commons/user-preferences/UserPreferences";

import { Proposals, Orders, Campaigns, Creatives } from "@marketron/react-components";

function GenericNotFound() {
  return <div>The page you're looking for doesn't exist.</div>;
}

function Routes() {
  const isStandalone = useSelector((state) => state.loginData.isStandalone);

  return (
    <Router>
      {GA.init() && <GA.RouteTracker />}
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/login/:tenantId/:token" component={TokenLogin} />
          <Route path="/login" component={LogIn} />
          <Route path="/logout" component={Logout} />
          <Route path="/frameTestPage" component={FrameTestPage} />
          <Authenticated>
            <UserPreferences />
            <Shell>
              <Switch>
                <Route path="/proposals" render={() => <Proposals isStandalone={isStandalone} />} />
                <Route path="/orders" render={() => <Orders isStandalone={isStandalone} />} />
                <Route path="/msporder" render={() => <Orders isStandalone={isStandalone} />} />
                <Route path="/campaigns" render={() => <Campaigns isStandalone={isStandalone} />} />
                <Route path="/creatives" render={() => <Creatives isStandalone={isStandalone} />} />
                <Route path="/mapboxgl" component={MapBoxGl} />
                <Route component={GenericNotFound} />
              </Switch>
            </Shell>
          </Authenticated>
          <Route component={GenericNotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default Routes;
